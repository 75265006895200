import React from "react";

import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { BlockAboutUs } from "../components/block-abaout-us";
import { Container } from "../components/container";
import { Cta } from "../components/cta";
import { LayoutPage } from "../components/layout-page";
import { MarqueeBrands } from "../components/marquee-brands";
import { Seo } from "../components/seo";
import { Title } from "../components/title";
import styles from "../styles/testimonios.css";
import { ButtonExpand } from "../ui/button-expand";

export default function TestimoniosPage({ data: { page } }: PageProps<any>) {
  return (
    <>
      <Seo
        description={page.seo.description}
        image={page.seo.image?.asset.gatsbyImageData.images.fallback.src}
        title={page.seo.title}
      />

      <LayoutPage
        heroButtonText={page.hero.button}
        heroDescription={page.hero.description}
        heroImage={page.hero.image.asset.gatsbyImageData}
        heroImageAlt="Testimonios"
        heroTitle={page.hero._rawTitle}
      >
        <div className={styles.marquee}>
          <MarqueeBrands />
        </div>

        <Container>
          <Title
            pretitle={page.sectionTestimonialsHeader.pretitle}
            subtitle={page.sectionTestimonialsHeader.subtitle}
            text={page.sectionTestimonialsHeader.title}
          />

          <div className={styles.testimonials}>
            {page.sectionTestimonialsItems.map((testimony: any) => (
              <div key={testimony._key} className={styles.testimonyContainer}>
                <div className={styles.testimonyDetails}>
                  <div className={styles.testimonyLogo}>
                    <GatsbyImage
                      alt={testimony.company}
                      image={testimony.image.asset.gatsbyImageData}
                    />
                  </div>
                  <div>
                    <p className={styles.testimonyLabel}>Industria</p>
                    <p className={styles.testimonyLabelItem}>{testimony.industry}</p>
                  </div>

                  <div className={styles.testimonyButton}>
                    <ButtonExpand text="Ver tienda" to={testimony.website} />
                  </div>
                </div>

                <div className={styles.testimonyWrap}>
                  <blockquote className={styles.testimonyTestimony}>
                    {testimony.testimony}
                  </blockquote>
                  <p className={styles.testimonyAuthor}>{testimony.name}</p>
                  <p className={styles.testimonyAuthorPosition}>
                    {testimony.position} en {testimony.company}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <Cta />
        </Container>

        <BlockAboutUs />
      </LayoutPage>
    </>
  );
}

export const query = graphql`
  query {
    page: sanityPageTestimonials {
      seo {
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, width: 1200, height: 630)
          }
        }
      }
      hero {
        _rawTitle
        description
        button
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 1050)
          }
        }
      }
      sectionTestimonialsHeader {
        pretitle
        title
        subtitle
      }
      sectionTestimonialsItems {
        _key
        company
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
        website
        industry
        testimony
        name
        position
      }
    }
  }
`;
