import React from "react";

import { PortableText, PortableTextProps } from "@portabletext/react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import { ButtonAnchorSpecial } from "../../ui/button-special";
import { BlobImage } from "../../ui/image-blob";
import { Container } from "../container";
import styles from "./layout-page.css";

interface Props {
  heroTitle: PortableTextProps["value"];
  heroDescription: string;
  heroButtonText: string;
  heroImage: IGatsbyImageData;
  heroImageAlt: string;
  children: React.ReactNode;
}

export const LayoutPage = ({
  heroTitle,
  heroDescription,
  heroButtonText,
  heroImage,
  heroImageAlt,
  children,
}: Props) => {
  return (
    <>
      <Container variant="hero">
        <div>
          <PortableText
            components={{
              block: {
                normal: ({ children }) => <h1>{children}</h1>,
              },
            }}
            value={heroTitle}
          />

          <p className={styles.description}>{heroDescription}</p>

          <ButtonAnchorSpecial anchor="#ver-mas" text={heroButtonText} variant="outline" />
        </div>

        <div className={styles.image}>
          <BlobImage id="image-hero" image={<GatsbyImage alt={heroImageAlt} image={heroImage} />} />
        </div>
      </Container>

      <div id="ver-mas">{children}</div>
    </>
  );
};
